<template>
	<b-card no-body>
		<b-card-body>
			<b-row>
                <!-- Code -->
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>Username</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="usernameFilter"
						class="w-100"
						@input="(val) => $emit('update:usernameFilter', val)"
					/>
				</b-col>
				<!-- Product -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>Product</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="productFilter"
						:options="productOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:productFilter', val)"
					/>
				</b-col>
				<!-- From -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<b-form-group
						label="From Date"
						label-for="fromDate"
					>
						<flat-pickr
							id="fromDate"
							v-model="fromFilter"
							class="form-control"
							placeholder="YYYY-MM-DD"
							@on-change="changeFromDate"
						/>
					</b-form-group>
				</b-col>
				<!-- To -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<b-form-group
						label="To Date"
						label-for="toDate"
					>
						<flat-pickr
							id="toDate"
							v-model="toFilter"
							class="form-control"
							placeholder="YYYY-MM-DD"
							@on-change="changeToDate"
						/>
					</b-form-group>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
				>
					<b-button
						variant="secondary"
						@click="resetFilter()"
					>
						Reset
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						Search
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormDatepicker, BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		vSelect,
		flatPickr,
	},
	props: {
		usernameFilter: {
			type: [String, null],
			default: null,
		},
		productFilter: {
			type: [String, null],
			default: null,
		},
		fromdateFilter: {
			type: [String, null],
			default: null,
		},
		todateFilter: {
			type: [String, null],
			default: null,
		},
		productOptions: {
			type: Array,
			required: true,
		},
	},
	methods: {
		changeFromDate() {
			this.$emit('update:fromdateFilter', this.fromFilter)
		},
		changeToDate() {
			this.$emit('update:todateFilter', this.toFilter)
		}
	},
	setup(props, { emit }) {
		const fromFilter = ref(null)
		const toFilter = ref(null)
		const resetFilter = () => {
			emit('update:usernameFilter', null)
			emit('update:productFilter', null)
			emit('update:fromdateFilter', null)
			emit('update:todateFilter', null)
			fromFilter.value = null
			toFilter.value = null
		}

		const searchFilter = () => {
			alert('waiting API ....')
		}
		return {
			resetFilter,
			searchFilter,
			fromFilter,
			toFilter,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
